import NextcloudClient from "nextcloud-link";
import Mime from "mime";

const client = new NextcloudClient({
  url: process.env.NEXTCLOUD_URL || "https://files.evk.pt",
  username: process.env.NEXTCLOUD_USERNAME || "bnp@evoke.pt",
  password: process.env.NEXTCLOUD_PASSWORD || "BnP2020!_",
});

const getFilesByShare = async (shareId) => {
  const share = await client.shares.get(shareId);

  return share;
};

const getFilesByPath = async (path) => {
  let files = await client.getFiles(path);

  const result = Promise.all(
    files.map(async (file, index) => {
      const dl = await client.getReadStream(path + "/" + file);
      const mimeType = Mime.getType(file);

      dl.mode = "disable-fetch";

      const base64 = new Promise((resolve, reject) => {
        dl.on("data", async (d) => {
          resolve(await handleFile(d, mimeType));
          reject("error");
        });
      });

      const binary = new Promise((resolve, reject) => {
        dl.on("data", async (d) => {
          resolve(await handleFileBinary(d, mimeType, file));
          reject("error");
        });
      });

      let src = await base64;
      let type = mimeType.split("/")[0];

      let f = {
        id: `${index + 1}`,
        src: src,
        binary: await binary,
        type: type,
      };

      return f;
    })
  );

  return await result;
};

const handleFile = async (file, mType) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(new Blob([file], { type: mType }));
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const handleFileBinary = async (file, mType, fileName) =>
  new Promise((resolve, reject) => {
    let blob = new Blob([file], { type: mType });
    resolve(new File([blob], fileName, { type: mType }));
  });

export { getFilesByShare, getFilesByPath };
